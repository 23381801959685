import * as Yup from 'yup'

export const validationSchema = Yup.object({
  name: Yup.string().required('*Product name is required'),
  category: Yup.array().of(Yup.number()).optional(),
  description: Yup.string().optional(),
  price: Yup.number()
    .required('*Price is required')
    .max(1000000, 'Price cannot exceed ₹10,00,000'),
  discountedPrice: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .lessThan(
      Yup.ref('price'),
      '*Discounted price should be less than the regular price'
    )
    .optional(),
  quantity: Yup.number().optional(),
  costPerUnit: Yup.number().optional(),
  discountPercentage: Yup.number().optional(),
  offerValidTill: Yup.array().of(Yup.date().nullable()),
  variants: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('*Name is required'),
        type: Yup.string().required('*Type is required'),
        price: Yup.number()
          .required('*Price is required')
          .max(1000000, '*Price cannot exceed ₹10,00,000'),
        quantity: Yup.number().required('*Quantity is required'),
        discountedPrice: Yup.number()
          .required('*Discounted price is required')
          .optional(),
        costPerUnit: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .optional(),
        discountPercentage: Yup.number().optional(),
        offerValidTill: Yup.array().of(Yup.date().nullable()).optional(),
      })
    )
    .optional(),
  seoTitle: Yup.string().required('*Seo title is required.'),
  seoDescription: Yup.string().optional(),
  seoLink: Yup.string()
    .required('*Please enter a valid product link')
    .matches(
      /^[a-zA-Z0-9_-]+$/,
      '*Product link can only contain alphanumeric characters, underscores, and dashes'
    ),
  notes: Yup.string().optional(),
  tagIds: Yup.array().of(Yup.number()).optional(),
  isActive: Yup.boolean().optional(),
  isListed: Yup.boolean().optional(),
})
