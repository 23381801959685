import { useEffect, useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Grid,
  Chip,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'

import Card, { SubCard } from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import EditButton from 'pages/dashboard/components/EditButton'
import TagInput from 'pages/dashboard/components/TagInput'
import { updateCustomer } from 'services/customers'
import {
  Customer,
  UpdateCustomerDetailsParams,
} from 'services/customers/index.type'
import { getTags } from 'services/tags'
import { Tag } from 'types/tag'

import AddressDialog from './AddressDialog'
import ContactInformation from './ContactInformation'
import RichTextEditor from './NotesEditor'

interface CustomerDetailsProps {
  data: Customer
  onUpdate?: () => void
  setData: (data: Customer) => void
}

const CustomerDetails = ({
  data,
  setData,
  onUpdate,
}: CustomerDetailsProps): JSX.Element => {
  const { customerId } = useParams()
  const { showSnackbar } = useSnackbar()
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingTags, setIsEditingTags] = useState(false)
  const [tags, setTags] = useState<number[]>(data.tagIds)
  const [tagNames, setTagNames] = useState<string[]>([])
  const [notes, setNotes] = useState<string | undefined>(data.notes)
  const [isDialogOpen, setIsDialogOpen] = useState({
    openEmpty: false,
    openFill: false,
  })
  const { organizationId } = useAuth()

  const handleOpen = (dialog: string): void => {
    setIsDialogOpen((prevState) => ({ ...prevState, [dialog]: true }))
  }

  const handleClose = (dialog: string): void => {
    setIsDialogOpen((prevState) => ({ ...prevState, [dialog]: false }))
  }
  const memberSince = format(new Date(data.createdAt), 'dd MMM yyyy')

  useEffect(() => {
    const get = async (orgId: number, tagIds: number[]): Promise<void> => {
      const result = await getTags('customer', orgId)
      if (result && result.status === 'successful' && result.data) {
        const data = result.data.filter((el: Tag) => tagIds.includes(el.id))
        const namesList = data.map((el: Tag) => el.name)
        setTagNames(namesList)
      }
    }
    if (organizationId && Array.isArray(data.tagIds) && data.tagIds.length > 0)
      get(organizationId, data.tagIds)
  }, [organizationId, data.tagIds])

  const handleSave = async () => {
    // Ensure customerId is defined and convert it to a number
    if (customerId) {
      const updatedData: UpdateCustomerDetailsParams = {
        notes: notes ? notes : undefined,
        tagIds: tags,
      }
      const result = await updateCustomer(Number(customerId), updatedData)
      if (result?.status === 'successful' && result.data) {
        setData(result.data)
      } else if (result?.status === 'failed') {
        // Handle the error appropriately
        result.message
          ? showSnackbar(result.message, 'error')
          : showSnackbar('Please try again later.', 'error')
      }
    }
  }

  const handleTagsChange = (newTags: number[]) => {
    setTags(newTags)
  }

  const handleNotesChange = (newNotes: string) => {
    setNotes(newNotes)
  }

  const handleSaveNotes = async () => {
    await handleSave()
    setIsEditingNotes(false)
  }

  const handleSaveTags = async () => {
    await handleSave()
    setIsEditingTags(false)
  }

  return (
    <div className="m-5">
      <Card>
        <div className="max-h-[75vh] flex flex-col">
          <Box className=" mb-5 px-2">
            <Typography sx={{ fontWeight: '700' }}>
              {data.firstName} {data.lastName}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Member since {memberSince}
            </Typography>
          </Box>
          <Box
            className="flex-1 overflow-y-auto px-2 "
            sx={{ scrollbarWidth: 'none' }}
          >
            <Box className=" mb-4">
              <Typography sx={{ fontWeight: '700' }}>Orders</Typography>
              <SubCard>
                {data.order && data.order.length === 0 && (
                  <Box textAlign={'center'}>
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        color: 'text.secondary',
                        fontWeight: '600',
                      }}
                    >
                      This customer hasn&apos;t placed any order yet
                    </Typography>
                    <Button
                      disableElevation
                      sx={{ marginTop: '1rem', textTransform: 'none' }}
                      variant="contained"
                      size="small"
                    >
                      Create Order
                    </Button>
                  </Box>
                )}
              </SubCard>
            </Box>
            <ContactInformation
              setData={setData}
              customerId={data.id}
              email={data.email}
              phoneNumber={data.phoneNumber}
              instagramId={data.instagramId}
            />
            <Box className="mb-4">
              <Typography sx={{ fontWeight: '700' }}>Addresses</Typography>
              {data.address.length <= 0 && (
                <Paper
                  className=" my-2 text-center p-5"
                  style={{
                    boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Button
                    onClick={() => handleOpen('openEmpty')}
                    variant="contained"
                    size="small"
                    sx={{ textTransform: 'none' }}
                  >
                    Add Address
                  </Button>
                  {isDialogOpen.openEmpty && (
                    <AddressDialog
                      onClose={() => handleClose('openEmpty')}
                      customerId={data.id}
                      onUpdate={onUpdate}
                    />
                  )}
                </Paper>
              )}

              {data.address.length > 0 && (
                <Grid container spacing={1}>
                  {data.address.map((addr) => {
                    return (
                      <Grid item sm={12} md={4} lg={3} key={addr.id}>
                        <Paper
                          className="my-2 py-3 px-5 relative h-40"
                          style={{
                            boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          <IconButton
                            sx={{
                              color: 'text.secondary',
                              position: 'absolute',
                              right: '0.4rem',
                              top: '0.4rem',
                            }}
                            onClick={() => handleOpen('openFill')}
                          >
                            <EditIcon fontSize={'small'} />
                          </IconButton>
                          {isDialogOpen.openFill && (
                            <AddressDialog
                              onClose={() => handleClose('openFill')}
                              address={addr}
                              customerId={data.id}
                              onUpdate={onUpdate}
                            />
                          )}

                          <Typography
                            sx={{ fontSize: '0.75rem', fontWeight: '600' }}
                          >
                            {addr.addressLine2}
                            <br />
                            {addr.addressLine1}
                            <br />
                            {addr.city !== addr.state && `${addr.city},`}{' '}
                            {addr.state}
                            <br />
                            {addr.country}
                            <br />
                            {addr.pinCode}
                            <br />
                            {addr.phoneNo}
                          </Typography>
                        </Paper>
                      </Grid>
                    )
                  })}
                  <Grid
                    item
                    sm={12}
                    md={1}
                    className="my-2 py-3 px-5"
                    alignContent={'center'}
                  >
                    <IconButton
                      onClick={() => handleOpen('openEmpty')}
                      sx={{
                        color: 'text.secondary',
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                    {isDialogOpen.openEmpty && (
                      <AddressDialog
                        onClose={() => handleClose('openEmpty')}
                        customerId={data.id}
                        onUpdate={onUpdate}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box className=" mb-4">
              <Box className=" flex gap-3 items-center">
                <Typography sx={{ fontWeight: '700' }}>Notes</Typography>
                <EditButton
                  buttonText={isEditingNotes ? 'Save' : 'Edit'}
                  onClick={() => {
                    if (isEditingNotes) {
                      handleSaveNotes()
                    } else {
                      setIsEditingNotes(true)
                    }
                  }}
                />
              </Box>
              <SubCard>
                {!isEditingNotes && (
                  <Typography>{data.notes || 'No notes available'}</Typography>
                )}
                {isEditingNotes && (
                  <RichTextEditor
                    notes={notes}
                    onNotesChange={handleNotesChange}
                  />
                )}
              </SubCard>
            </Box>
            <Box className=" mb-4">
              <Box className=" flex gap-3 items-center">
                <Typography sx={{ fontWeight: '700' }}>Tags</Typography>
                <EditButton
                  buttonText={isEditingTags ? 'Save' : 'Edit'}
                  onClick={() => {
                    if (isEditingTags) {
                      handleSaveTags()
                    } else {
                      setIsEditingTags(true)
                    }
                  }}
                />
              </Box>
              <SubCard>
                {isEditingTags ? (
                  <TagInput
                    entity="customer"
                    onChange={handleTagsChange}
                    defaultValues={tags}
                  />
                ) : (
                  <Box>
                    {tagNames.length > 0 ? (
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                        {tagNames.map((tag, index) => (
                          <Chip key={index} label={tag} variant="outlined" />
                        ))}
                      </Stack>
                    ) : (
                      <Typography>N/A</Typography>
                    )}
                  </Box>
                )}
              </SubCard>
            </Box>
          </Box>
        </div>
      </Card>
    </div>
  )
}

export default CustomerDetails
