import { useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton, Typography, TextField, Button } from '@mui/material'

interface TitleSectionProps {
  title: string
  onBackClick: () => void
  onSave: (newTitle: string) => void
}

const CategoryTitleSection = ({
  title,
  onBackClick,
  onSave,
}: TitleSectionProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedTitle, setEditedTitle] = useState(title)
  const [isHovered, setIsHovered] = useState(false)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    onSave(editedTitle)
    setIsEditing(false)
  }

  const handleCancel = () => {
    setIsEditing(false)
    setEditedTitle(title)
  }

  if (title !== editedTitle && !isEditing) {
    setEditedTitle(title)
  }

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
      className="mx-3 mt-4"
    >
      <IconButton onClick={onBackClick} sx={{ color: 'black' }}>
        <ArrowBackIcon />
      </IconButton>

      {isEditing ? (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <TextField
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            size="small"
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ ml: 1 }}
            size="small"
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            sx={{ ml: 1 }}
            size="small"
          >
            Cancel
          </Button>
        </Box>
      ) : (
        <Box
          sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Typography variant="h6">{editedTitle}</Typography>
          {isHovered && (
            <IconButton onClick={handleEdit} sx={{ ml: 1 }}>
              <EditIcon sx={{ color: 'black' }} />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  )
}

export default CategoryTitleSection
