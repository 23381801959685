import { ChangeEvent, FC, useState } from 'react'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Box, Typography } from '@mui/material'

import { SubCard } from 'components/card'
import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import TextCollapse from 'pages/dashboard/components/TextCollapse'
import { updateProduct } from 'services/products'

interface SeoDetailsProps {
  seoValues: {
    seoTitle?: string
    seoLink?: string
    seoDescription?: string
  }
  productId: number
  onUpdate?: () => void
}

const SeoDetails: FC<SeoDetailsProps> = ({
  seoValues,
  productId,
  onUpdate,
}) => {
  const [edit, setEdit] = useState(false)
  const [values, setValues] = useState({
    seoTitle: seoValues.seoTitle,
    seoLink: seoValues.seoLink,
    seoDescription: seoValues.seoDescription,
  })
  const { organizationId } = useAuth()

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'seoTitle') {
      setValues((prev) => ({ ...prev, seoTitle: e.target.value }))
    } else if (e.target.name === 'seoLink')
      setValues((prev) => ({ ...prev, seoLink: e.target.value }))
  }

  const handleDescriptionChange = (
    event: unknown,
    editor: ClassicEditor
  ): void => {
    const data = editor.getData()
    setValues((prev) => ({ ...prev, seoDescription: data }))
  }

  const handleEdit = async (): Promise<void> => {
    if (!edit && organizationId) {
      Amplitude.trackEvent('PRODUCT_SEO_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }

    if (
      edit &&
      (seoValues.seoTitle !== values.seoTitle ||
        seoValues.seoLink !== values.seoLink ||
        seoValues.seoDescription !== values.seoDescription)
    ) {
      const res = await updateProduct({
        productId,
        dataUpdate: {
          seoTitle: values.seoTitle,
          seoLink: values.seoLink,
          seoDescription: values.seoDescription,
        },
      })
      if (res.status === 'successful') {
        onUpdate?.()
      }

      if (organizationId) {
        Amplitude.trackEvent('PRODUCT_SEO_SAVE_CLICKED', {
          productId: productId,
          orgId: organizationId,
        })
      }
    }
    setEdit((prev) => !prev)
  }

  return (
    <div className="mb-5">
      <SubTabHeader
        title="Search Engine Optimization (SEO)"
        buttonText={edit ? 'Save' : 'Edit'}
        onClick={handleEdit}
      />
      <SubCard>
        <Typography
          sx={{
            fontSize: '0.8rem',
            color: 'text.secondary',
            fontWeight: '600',
          }}
        >
          Title
        </Typography>
        {edit ? (
          <CustomTextField
            name="seoTitle"
            value={values.seoTitle}
            onChange={handleChange}
          />
        ) : (
          <Typography sx={{ fontWeight: '700', mb: 2 }}>
            {seoValues.seoTitle ?? 'N/A'}
          </Typography>
        )}

        <Typography
          sx={{
            fontSize: '0.8rem',
            color: 'text.secondary',
            fontWeight: '600',
          }}
        >
          Link
        </Typography>
        {edit ? (
          <CustomTextField
            name="seoLink"
            value={values.seoLink}
            onChange={handleChange}
          />
        ) : (
          <Typography sx={{ fontWeight: '700', mb: 2 }}>
            {seoValues.seoLink ?? 'N/A'}
          </Typography>
        )}
        <Typography
          sx={{
            fontSize: '0.8rem',
            color: 'text.secondary',
            fontWeight: '600',
          }}
        >
          Description
        </Typography>
        {edit ? (
          <Box className=" flex">
            <Box className="flex-1 w-14">
              <CKEditor
                editor={ClassicEditor}
                data={values.seoDescription}
                onChange={handleDescriptionChange}
              />
            </Box>
          </Box>
        ) : (
          <TextCollapse text={seoValues.seoDescription ?? 'N/A'} />
        )}
      </SubCard>
    </div>
  )
}

export default SeoDetails
