import React from 'react'

import { Container, Grid, Typography, Button, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

const FooterContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid #e0e0e0',
}))

const FooterGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    textAlign: 'center',
  },
}))

const FooterButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'none',
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(1),
  },
}))

const Footer: React.FC = () => {
  const navigate = useNavigate()

  const handlePrivacyNavigate = (): void => {
    navigate('/privacy-policy')
  }

  const handleTermsNavigate = (): void => {
    navigate('/terms-of-service')
  }

  const handleReturnsNavigate = (): void => {
    navigate('/refund-and-cancellation')
  }

  return (
    <FooterContainer>
      <Container>
        <FooterGrid container>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <Typography variant="h6" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Address: 224, 3RD FLOOR, #80/3, VIJINAPUR VILLAGE, Doorvaninagar,{' '}
              <br />
              Bangalore North, Bangalore- 560016, Karnataka
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              textAlign: 'left',
            }}
          >
            <Box>
              <Typography variant="h6" gutterBottom>
                Policies
              </Typography>
              <FooterButton onClick={handleTermsNavigate}>
                Terms of Service
              </FooterButton>
              <FooterButton onClick={handlePrivacyNavigate}>
                Privacy Policy
              </FooterButton>
              <FooterButton onClick={handleReturnsNavigate}>
                Returns and Refunds
              </FooterButton>
            </Box>
          </Grid>
        </FooterGrid>

        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Typography variant="body1" color="textSecondary">
            © 2024 Cartcrest E-Market Solutions Private Limited
          </Typography>
        </Box>
      </Container>
    </FooterContainer>
  )
}

export default Footer
