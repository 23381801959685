import React, { ReactNode } from 'react'

import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Navbar } from 'components/navbar'
import {
  PrivacyPolicy,
  RefundAndCancellation,
  TermsOfService,
} from 'components/PolicyDocuments'
import { AuthProvider } from 'contexts/authContext'
import { SnackbarProvider } from 'contexts/snackbarContext'
import { TemplateContentProvider } from 'contexts/template/templateContentContext'
import { TemplateProvider } from 'contexts/template/templateContext'
import Register from 'pages/auth/Register'
import SignIn from 'pages/auth/SignIn'
import Dashboard from 'pages/dashboard'
import Analytics from 'pages/dashboard/pages/analytics'
import AnalyticsPage from 'pages/dashboard/pages/analytics/pages/AnalyticsPage'
import Customers from 'pages/dashboard/pages/customers'
import AddCustomerPage from 'pages/dashboard/pages/customers/pages/AddCustomerPage'
import CustomersDetailsPage from 'pages/dashboard/pages/customers/pages/CustomersDetailsPage'
import CustomersPage from 'pages/dashboard/pages/customers/pages/CustomersPage'
import DashboardHome from 'pages/dashboard/pages/home'
import Email from 'pages/dashboard/pages/marketing/pages/email'
import Orders from 'pages/dashboard/pages/orders'
import OrdersPage from 'pages/dashboard/pages/orders/pages/OrdersPage'
import Products from 'pages/dashboard/pages/products'
import CategoryList from 'pages/dashboard/pages/products/components/categories'
import AddCategoryPage from 'pages/dashboard/pages/products/components/categories/components/AddNewCategory'
import CategoryDetailsPage from 'pages/dashboard/pages/products/components/categories/components/CategoryDetailsPage'
import AddProductPage from 'pages/dashboard/pages/products/pages/AddProductPage'
import ProductDetailsPage from 'pages/dashboard/pages/products/pages/ProductDetailsPage'
import ProductsPage from 'pages/dashboard/pages/products/pages/ProductsPage'
import AccountSettings from 'pages/dashboard/pages/settings/pages/account'
import PaymentSettings from 'pages/dashboard/pages/settings/pages/payment'
import StoreSettings from 'pages/dashboard/pages/settings/pages/stores'
import StockPage from 'pages/dashboard/pages/stock/pages/StockPage'
import WebsiteBuilder from 'pages/dashboard/pages/website'
import EditorPage from 'pages/editor'
import Home from 'pages/home'
import ProtectedRoute from 'routes/ProtectedRoute'

interface AppProps {
  children: ReactNode
}

const App: React.FC<AppProps> = () => {
  return (
    <AuthProvider>
      <TemplateProvider>
        <TemplateContentProvider>
          <SnackbarProvider>
            <HelmetProvider>
              <BrowserRouter>
                <Helmet>
                  <title>Haulistic</title>
                  <link
                    rel="icon"
                    type="image/png"
                    href={
                      process.env.REACT_APP_ASSETS_URL +
                      '/images/logos/logo.png'
                    }
                  />
                </Helmet>
                <Navbar />
                <Routes>
                  <Route path="" element={<Home />} />
                  <Route path="signin" element={<SignIn />} />
                  <Route path="register" element={<Register />} />
                  <Route
                    path="dashboard"
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  >
                    <Route path="home" element={<DashboardHome />} />
                    <Route path="customers" element={<Customers />}>
                      <Route index element={<CustomersPage />} />
                      <Route
                        path=":customerId"
                        element={<CustomersDetailsPage />}
                      />
                      <Route path="new" element={<AddCustomerPage />} />
                    </Route>
                    <Route path="orders" element={<Orders />}>
                      <Route index element={<OrdersPage />} />
                    </Route>
                    <Route path="products" element={<Products />}>
                      <Route path="all" element={<ProductsPage />} />
                      <Route path="stock" element={<StockPage />} />
                      <Route path="new" element={<AddProductPage />} />
                      <Route path="categories" element={<CategoryList />} />
                      <Route
                        path="categories/:categoryId"
                        element={<CategoryDetailsPage />}
                      />
                      <Route
                        path="categories/new"
                        element={<AddCategoryPage />}
                      />
                      <Route
                        path=":productId"
                        element={<ProductDetailsPage />}
                      />
                    </Route>
                    <Route path="analytics" element={<Analytics />}>
                      <Route index element={<AnalyticsPage />} />
                    </Route>
                    <Route
                      path="website-builder"
                      element={<WebsiteBuilder />}
                    />
                    <Route path="settings">
                      <Route path="account" element={<AccountSettings />} />
                      <Route path="stores" element={<StoreSettings />} />
                      <Route path="payments" element={<PaymentSettings />} />
                    </Route>
                    <Route path="marketing">
                      <Route path="email" element={<Email />} />
                    </Route>
                    <Route path="editor" element={<EditorPage />} />
                  </Route>
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="terms-of-service" element={<TermsOfService />} />
                  <Route
                    path="refund-and-cancellation"
                    element={<RefundAndCancellation />}
                  />
                </Routes>
              </BrowserRouter>
            </HelmetProvider>
          </SnackbarProvider>
        </TemplateContentProvider>
      </TemplateProvider>
    </AuthProvider>
  )
}

export default App
