import { FC, useEffect, useState } from 'react'

import { Chip, Stack, Typography } from '@mui/material'

import { SubCard } from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import TagInput from 'pages/dashboard/components/TagInput'
import { updateProduct } from 'services/products'
import { getTags } from 'services/tags'
import { Tag } from 'types/tag'

interface TagDetailsProps {
  productId: number
  tagIds: number[]
  onUpdate?: () => void
}

const TagsDetails: FC<TagDetailsProps> = ({ productId, tagIds, onUpdate }) => {
  const [tags, setTags] = useState<number[]>([])
  const [tagNames, setTagNames] = useState<string[]>([])
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()

  useEffect(() => {
    const get = async (orgId: number): Promise<void> => {
      const result = await getTags('product', orgId)
      if (result && result.status === 'successful') {
        const data = result.data.filter((el: Tag) => tagIds.includes(el.id))
        const namesList = data.map((el: Tag) => el.name)
        setTagNames(namesList)
      }
    }
    if (organizationId) get(organizationId)
  }, [organizationId, tagIds])

  const handleEdit = async (): Promise<void> => {
    if (!edit && organizationId) {
      Amplitude.trackEvent('PRODUCT_TAGS_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    if (edit && organizationId) {
      Amplitude.trackEvent('PRODUCT_TAGS_SAVE_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
      const result = await updateProduct({
        productId,
        dataUpdate: { tagIds: tags },
      })
      if (result.status === 'successful' && result.data) {
        setTags(result.data.tagIds)
        showSnackbar('Tags updated.', 'success')
        onUpdate?.()
      }
    }
    setEdit(!edit)
  }

  const handleTagsChange = (tags: number[]): void => {
    setTags(tags)
  }

  return (
    <div>
      <div className="mb-5">
        <SubTabHeader
          title="Tags"
          buttonText={edit ? 'Save' : 'Edit'}
          onClick={handleEdit}
        />
        <SubCard>
          {!edit &&
            (tagNames.length > 0 ? (
              <Stack direction="row" spacing={1} flexWrap="wrap">
                {tagNames.map((tag, index) => (
                  <Chip key={index} label={tag} variant="outlined" />
                ))}
              </Stack>
            ) : (
              <Typography>None</Typography>
            ))}
          {edit && (
            <TagInput
              entity="product"
              onChange={handleTagsChange}
              defaultValues={tagIds}
            />
          )}
        </SubCard>
      </div>
    </div>
  )
}

export default TagsDetails
