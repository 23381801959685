import { useState } from 'react'

import {
  GridEventListener,
  GridRenderCellParams,
  useGridApiContext,
  useGridApiEventHandler,
} from '@mui/x-data-grid'
import { useNavigate } from 'react-router-dom'

const CustomCell: React.FC<GridRenderCellParams> = ({
  id,
  value,
}): JSX.Element => {
  const apiRef = useGridApiContext()
  const navigate = useNavigate()

  const [rowHover, setRowHover] = useState(false)
  const handleRowMouseEnterEvent: GridEventListener<'rowMouseEnter'> = (
    params
  ) => {
    if (params.id === id) setRowHover(true)
  }
  const handleRowMouseLeaveEvent: GridEventListener<'rowMouseLeave'> = (
    params
  ) => {
    if (params.id === id) setRowHover(false)
  }

  useGridApiEventHandler(apiRef, 'rowMouseEnter', handleRowMouseEnterEvent)
  useGridApiEventHandler(apiRef, 'rowMouseLeave', handleRowMouseLeaveEvent)

  return (
    <div className="relative">
      <span
        className={`${rowHover ? ' underline' : ''} cursor-pointer`}
        onClick={() => navigate(`/dashboard/products/${id}`)}
      >
        {value}
      </span>
    </div>
  )
}

export default CustomCell
