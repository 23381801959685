import { ChangeEvent, FC, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { Grid, Typography, TextField, IconButton, Link } from '@mui/material'

import Card from 'components/card'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { getPresignedUrl, uploadFile, deleteFile } from 'services/files'
import { validateCategoryImage } from 'utils/validation'

interface CategoryDetailsProps {
  values: { name: string; description: string }
  errors: { [key: string]: string }
  touched: { [key: string]: boolean }
  handleChange: (e: React.ChangeEvent<unknown>) => void
  setImageUrl: (url: string) => void
}

const fileUrl = process.env.REACT_APP_FILE_URL

const CategoryDetails: FC<CategoryDetailsProps> = ({
  values,
  errors,
  touched,
  handleChange,
  setImageUrl,
}) => {
  const [image, setImage] = useState<string | null>(null)
  const [error, setError] = useState<{ type: string; message: string } | null>(
    null
  )
  const [uploadProgress, setUploadProgress] = useState<{
    progress: number
    fileName: string
  }>({
    progress: 0,
    fileName: '',
  })
  const [isUploading, setIsUploading] = useState(false)

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return

    const file = e.target.files[0]
    const validate = await validateCategoryImage(file)

    if (!validate.valid)
      return setError({
        type: 'image',
        message: validate.error || 'An error occurred.',
      })

    setIsUploading(true)
    const signedUrl = await getPresignedUrl(file.name)
    setUploadProgress({ progress: 0, fileName: file.name })

    const result = await uploadFile(file, signedUrl, (progress) => {
      setUploadProgress({ progress, fileName: file.name })
    })

    if (result.status === 'successful') {
      const imageUrl = `${fileUrl}/${file.name}`
      setImage(imageUrl)
      setImageUrl(imageUrl)
      setError(null)
    }
    setIsUploading(false)
  }

  const handleDeleteImage = async (): Promise<void> => {
    if (!image) return

    const fileName = image.split('/')[3]
    const result = await deleteFile(fileName)

    if (result.status === 'successful') {
      setImage(null)
      setImageUrl('')
    }
  }

  return (
    <div className="mb-5">
      <h2 className="text-lg font-semibold ml-5">Details</h2>
      <Card>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Name
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              id="name"
              name="name"
              size="small"
              value={values.name}
              onChange={handleChange}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
              sx={{
                backgroundColor: 'white',
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Description
            </Typography>
            <textarea
              id="description"
              name="description"
              value={values.description}
              onChange={handleChange}
              rows={4}
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '4px',
                border:
                  touched.description && errors.description
                    ? '1px solid red'
                    : '1px solid #ccc',
                resize: 'vertical',
              }}
              className="py-2 px-3 focus:border-black focus:outline-none"
            />
            {touched.description && errors.description && (
              <p className="text-xs text-red-500 block mt-1">
                {errors.description}
              </p>
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Image
            </Typography>
            {image ? (
              <>
                <Link
                  href={image}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {image.split('/')[3]}
                </Link>
                <IconButton
                  onClick={handleDeleteImage}
                  aria-label="delete"
                  size="small"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    className="text-gray-600 hover:text-red-600"
                  />
                </IconButton>
              </>
            ) : (
              <InputFileUpload
                name="image"
                onChange={handleFileChange}
                buttonText={isUploading ? 'Uploading' : 'Upload File'}
              />
            )}
            {uploadProgress.progress > 0 && uploadProgress.progress < 100 && (
              <Typography sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
                {uploadProgress.fileName} - {uploadProgress.progress}%
              </Typography>
            )}
            {error && error.type === 'image' && (
              <p className="text-xs text-red-500 block mt-1">{error.message}</p>
            )}
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default CategoryDetails
