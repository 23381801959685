import { useEffect, useState } from 'react'

import { Box, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'

import Card from 'components/card'
import { getProduct } from 'services/products'
import { Product } from 'services/products/index.types'

import FilesDetails from '../components/FilesDetails'
import NotesDetails from '../components/NotesDetails'
import Preview from '../components/Preview'
import PricingDetails from '../components/PricingDetails'
import BasicDetails from '../components/ProductDetails'
import SeoDetails from '../components/SeoDetails'
import TagsDetails from '../components/TagsDetails'
import VariantsDetails from '../components/VariantsDetails'

const ProductDetailsPage = (): JSX.Element => {
  const [product, setProduct] = useState<Product>()
  const [dataUpdate, setDataUpdate] = useState(false)
  const { productId } = useParams()

  useEffect(() => {
    async function get(): Promise<void> {
      const result = productId
        ? await getProduct({ id: Number(productId) })
        : { status: 'failed' }
      if (result.status === 'successful') setProduct(result.data)
    }
    get()
  }, [productId])

  useEffect(() => {
    async function get(): Promise<void> {
      const result = productId
        ? await getProduct({ id: Number(productId) })
        : { status: 'failed' }
      if (result.status === 'successful') {
        setProduct(result.data)
      }
      setDataUpdate(false)
    }
    if (dataUpdate) get()
  }, [dataUpdate, productId])

  if (!product) return <h1>No Product found.</h1>

  const variants = product.variants.map((variant) => ({
    id: variant.id,
    type: variant.type,
    name: variant.name,
    price: variant.price,
    discountedPrice: variant.discountedPrice,
  }))

  const previewProductData = {
    name: product.name,
    price: product.price,
    discountedPrice: product.discountedPrice,
    description: product.description,
    variants: variants,
  }
  const mediaFiles = product.media.map((m) => m.split('/')[3])

  const handleUpdate = (): void => {
    setDataUpdate(true)
  }

  return (
    <Box className=" mx-5">
      <Grid container>
        <Grid item xs={12} sm={12} md={7}>
          <Card>
            <Box
              className="max-h-[80vh] overflow-y-auto px-2"
              sx={{ scrollbarWidth: 'none' }}
            >
              <BasicDetails
                productId={product.id}
                name={product.name}
                description={product.description}
                category={product.categoryIds}
                onUpdate={handleUpdate}
              />
              {product.price !== 0 && product.discountedPrice !== 0 && (
                <PricingDetails
                  productId={product.id}
                  price={product.price}
                  discountedPrice={product.discountedPrice}
                  onUpdate={handleUpdate}
                />
              )}
              <FilesDetails
                media={product.media}
                productId={product.id}
                onUpdate={handleUpdate}
              />
              {variants.length > 0 && (
                <VariantsDetails
                  productId={product.id}
                  variants={variants}
                  onUpdate={handleUpdate}
                />
              )}
              <SeoDetails
                seoValues={{
                  seoTitle: product.seoTitle,
                  seoDescription: product.seoDescription,
                  seoLink: product.seoLink,
                }}
                productId={product.id}
                onUpdate={handleUpdate}
              />
              <NotesDetails productId={product.id} notesData={product.notes} />
              <TagsDetails
                productId={product.id}
                tagIds={product.tagIds}
                onUpdate={handleUpdate}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <Preview files={mediaFiles} product={previewProductData} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProductDetailsPage
