import { Product } from 'types/product'
import { getAuthCookie, getToken } from 'utils/authCookies'
import { buildProductUrl } from 'utils/helper'

import {
  CreateProductParams,
  GetProductProps,
  ProductQueryParams,
  UpdateProductProps,
} from './index.types'

const productBasePath = `${process.env.REACT_APP_API_BASE_PATH}/products`

const getProduct = async ({ id }: GetProductProps) => {
  const token = getAuthCookie('IdToken')
  try {
    const res = await fetch(`${productBasePath}/${id}`, {
      method: 'GET',
      headers: {
        Authorization: ` Bearer ${token}`,
      },
    })

    const { data } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error getting presigned URL:', error)
    throw error // Rethrow the error to be handled by the caller
  }
}

const getAllProducts = async (query: ProductQueryParams) => {
  const token = await getToken()
  const url = buildProductUrl(productBasePath, query)

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    const { data } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error getting products:', error)
    throw error
  }
}

const updateProduct = async ({
  productId,
  dataUpdate,
}: UpdateProductProps): Promise<{ status: string; data?: Product }> => {
  const token = getAuthCookie('IdToken')
  try {
    const res = await fetch(`${productBasePath}/${productId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${token}`,
      },
      body: JSON.stringify(dataUpdate),
    })

    const { data } = await res.json()
    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed' }
  } catch (error) {
    console.error('Error getting presigned URL:', error)
    throw error // Rethrow the error to be handled by the caller
  }
}

export const createProduct = async (
  newProduct: Partial<CreateProductParams>
): Promise<{ status: string; data?: Product; message?: string }> => {
  const token = getAuthCookie('IdToken')
  try {
    const res = await fetch(productBasePath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${token}`,
      },
      body: JSON.stringify(newProduct),
    })

    const { data, message } = await res.json()

    if (res.ok) {
      return { status: 'successful', data }
    }
    return { status: 'failed', message }
  } catch (error) {
    console.error('Error getting presigned URL:', error)
    throw error // Rethrow the error to be handled by the caller
  }
}

const createCategory = async (createData: {
  name: string
  organizationId: number
}) => {
  const token = getAuthCookie('IdToken')

  try {
    const res = await fetch(`${productBasePath}/category`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${token}`,
      },
      body: JSON.stringify(createData),
    })
    const { data } = await res.json()
    if (res.ok) return { status: 'successful', data: data }
  } catch (error) {
    console.error('Error', error)
  }
}

const getCategories = async (organizationId: number) => {
  const token = getAuthCookie('IdToken')
  try {
    const res = await fetch(`${productBasePath}/categories/${organizationId}`, {
      method: 'GET',
      headers: {
        Authorization: ` Bearer ${token}`,
      },
    })
    const { data } = await res.json()

    if (res.ok) return { status: 'successful', data: data }
  } catch (error) {
    console.error('Error', error)
  }
}

export {
  getProduct,
  getAllProducts,
  updateProduct,
  createCategory,
  getCategories,
}
