import { FC, useEffect, useState } from 'react'

import { useTheme } from '@mui/material'
import { MultiValue } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { useAuth } from 'hooks/useAuth'
import { createTag, getTags } from 'services/tags'
import { Tag } from 'types/tag'

type OptionType = {
  label: string
  value: string
  id: number
}

interface TagInputProps {
  entity: 'product' | 'customer'
  onChange: (ids: number[]) => void
  defaultValues?: number[]
}

const TagInput: FC<TagInputProps> = ({ entity, onChange, defaultValues }) => {
  const theme = useTheme()
  const { organizationId } = useAuth()
  const [tagOptions, setTagOptions] = useState<MultiValue<OptionType>>([])
  const [value, setValue] = useState<MultiValue<OptionType>>()

  useEffect(() => {
    const get = async (orgId: number): Promise<void> => {
      const result = await getTags(entity, orgId)

      if (result && result.status === 'successful') {
        const optionsData = result.data.map((tag: Tag) => ({
          label: tag.name,
          value: tag.name,
          id: tag.id,
        }))
        setTagOptions(optionsData)
        if (defaultValues && defaultValues.length > 0) {
          const values = optionsData.filter((val: OptionType) =>
            defaultValues.includes(val.id)
          )
          setValue(values)
        }
      }
    }
    if (organizationId) get(organizationId)
  }, [organizationId, entity, defaultValues])

  const handleChange = (newValue: MultiValue<OptionType>): void => {
    setValue(newValue)
    onChange(newValue.map((val) => val.id))
  }

  const handleCreate = async (newValue: string): Promise<void> => {
    const result = organizationId
      ? await createTag({
          name: newValue.toLowerCase(),
          organizationId,
          entity,
        })
      : undefined

    if (result && result.status === 'successful') {
      const newOption: OptionType = {
        label: newValue,
        value: newValue.toLowerCase(),
        id: result.data.id,
      }
      setTagOptions((prev) => [...prev, newOption])
      handleChange([...(value || []), newOption])
    }
  }

  return (
    <CreatableSelect
      name="tags"
      isMulti
      options={tagOptions}
      value={value}
      onChange={handleChange}
      onCreateOption={handleCreate}
      styles={{
        control: (base, state) => ({
          ...base,
          minHeight: '40px',
          backgroundColor: theme.palette.background.paper,
          borderWidth: state.isFocused ? '2px' : '1px',
          borderColor: state.isFocused
            ? theme.palette.primary.main
            : 'rgba(0, 0, 0, 0.23)',
          boxShadow: 'none',
          '&:hover': {
            borderColor: state.isFocused
              ? theme.palette.primary.main
              : '#333333',
          },
        }),
        menu: (base) => ({
          ...base,
          zIndex: 2,
        }),
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isSelected
            ? theme.palette.action.selected
            : state.isFocused
              ? theme.palette.action.hover
              : theme.palette.background.paper,
          color: theme.palette.text.primary,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
      }}
    />
  )
}

export default TagInput
