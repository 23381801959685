// utils/validation.js
export const validateFile = (
  file: File,
  type: string
): Promise<{
  valid: boolean
  error: string
}> => {
  const allowedTypes = ['image/jpeg', 'image/png', 'image/svg+xml']
  const maxFileSize = 5 * 1024 * 1024 // 5MB

  if (!allowedTypes.includes(file.type)) {
    return Promise.resolve({
      valid: false,
      error: 'Invalid file type. Only JPEG, PNG, and SVG are allowed.',
    })
  }

  if (file.size > maxFileSize) {
    return Promise.resolve({
      valid: false,
      error: 'File size exceeds the maximum limit of 5MB.',
    })
  }

  return new Promise<{ valid: boolean; error: string }>((resolve) => {
    const reader = new FileReader()
    reader.onload = (e): void => {
      const img = new Image()
      img.onload = (): void => {
        if (
          (type === 'icon' || type === 'logo') &&
          (img.width > 500 || img.height > 500)
        ) {
          resolve({
            valid: false,
            error: 'Icons and logos must not exceed 500x500 pixels.',
          })
        } else if (
          type === 'cover' &&
          (img.width > 2000 || img.height > 1000)
        ) {
          resolve({
            valid: false,
            error: 'Cover images must not exceed 2000x1000 pixels.',
          })
        } else {
          resolve({ valid: true, error: '' })
        }
      }
      img.onerror = (): void => {
        resolve({
          valid: false,
          error: 'Failed to load the image.',
        })
      }
      img.src = e.target?.result as string
    }
    reader.onerror = (): void => {
      resolve({
        valid: false,
        error: 'Failed to read the file.',
      })
    }
    reader.readAsDataURL(file)
  })
}
interface ProductImageValidation {
  valid: boolean
  errorType?: string
  message: string
}

export async function validateProductImage(
  file: File
): Promise<ProductImageValidation> {
  const minWidth = 600
  const minHeight = 800
  const maxWidth = 1500
  const maxHeight = 2000
  const maxFileSize = 2 * 1024 * 1024
  const allowedAspectRatio = 3 / 4
  const allowedFormats = ['image/jpeg', 'image/png']

  if (!allowedFormats.includes(file.type)) {
    return {
      valid: false,
      message: 'Invalid file format. Only JPEG and PNG are allowed.',
    }
  }

  // Check file size
  if (file.size > maxFileSize) {
    return { valid: false, message: 'File size exceeds the limit of 2 MB.' }
  }

  // Create an Image object to check dimensions
  const img = new Image()
  const url = URL.createObjectURL(file)

  return new Promise<ProductImageValidation>((resolve) => {
    img.onload = (): void => {
      const width = img.width
      const height = img.height
      const aspectRatio = width / height
      // Check dimensions
      if (width < minWidth || height < minHeight) {
        resolve({
          valid: false,
          message:
            'Image dimensions are too small. Minimum size is 600x800 pixels.',
        })
      } else if (width > maxWidth || height > maxHeight) {
        resolve({
          valid: false,
          message:
            'Image dimensions are too large. Maximum size is 1500x2000 pixels.',
        })
      } else if (Math.abs(aspectRatio - allowedAspectRatio) > 0) {
        resolve({
          valid: false,
          errorType: 'AspectRatio',
          message: `Invalid aspect ratio. Expected 3:4.`,
        })
      } else {
        resolve({ valid: true, message: 'Image is valid.' })
      }

      URL.revokeObjectURL(url) // Clean up the object URL
    }

    img.onerror = (): void => {
      resolve({ valid: false, message: 'Error loading image.' })
      URL.revokeObjectURL(url) // Clean up the object URL
    }

    img.src = url // Start loading the image
  })
}

export const validateCategoryImage = (
  file: File
): Promise<{ valid: boolean; error?: string }> => {
  return new Promise((resolve) => {
    const validFileTypes = ['image/png', 'image/jpeg', 'image/jpg']
    const maxSizeInBytes = 2 * 1024 * 1024 // 2MB

    // Check file type
    if (!validFileTypes.includes(file.type)) {
      return resolve({
        valid: false,
        error: 'Invalid file type. Please upload a PNG or JPEG image.',
      })
    }

    // Check file size
    if (file.size > maxSizeInBytes) {
      return resolve({
        valid: false,
        error: 'File size should be less than 2MB.',
      })
    }

    // Check image dimensions
    const image = new Image()
    image.src = URL.createObjectURL(file)
    image.onload = () => {
      if (image.width !== 600 || image.height !== 800) {
        return resolve({
          valid: false,
          error: 'Image dimensions should be 600x800.',
        })
      }

      // If all validations pass
      resolve({ valid: true })
    }
  })
}
