import { FC, useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'

import { SubCard } from 'components/card'
import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'

import VariantsTable from './VariantsTable'

const variantsTableColumns = ['Name', 'Price', 'Discounted Price']

type VariantType = {
  id?: number
  type: string
  name: string
  price: number | string
  discountedPrice: number | string
}

interface VariantDetailsProps {
  variants?: VariantType[]
  productId: number
  onUpdate?: () => void
}

const VariantsDetails: FC<VariantDetailsProps> = ({
  variants = [],
  productId,
  onUpdate,
}) => {
  const [edit, setEdit] = useState(false)
  const [colorVariants, setColorVariants] = useState<VariantType[]>([])
  const [sizeVariants, setSizeVariants] = useState<VariantType[]>([])
  const [error, setError] = useState<{ message: string } | null>(null)
  const { organizationId } = useAuth()

  useEffect(() => {
    const colorV = variants.filter((v) => v.type === 'color')
    const sizeV = variants.filter((v) => v.type === 'size')

    setColorVariants(colorV)
    setSizeVariants(sizeV)
  }, [variants])

  const onColorTableRowsChange = (arr: VariantType[]): void => {
    setColorVariants(arr)
  }

  const onSizeTableRowsChange = (arr: VariantType[]): void => {
    setSizeVariants(arr)
  }

  const handleEditChange = async (): Promise<void> => {
    if (!edit && organizationId) {
      Amplitude.trackEvent('PRODUCT_VARIANT_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    if (edit) {
      const arr = [...colorVariants, ...sizeVariants]

      if (arr.length === 0) {
        setError({ message: 'Product should have at least one variant.' })
        return
      }

      if (organizationId) {
        Amplitude.trackEvent('PRODUCT_VARIANT_SAVE_CLICKED', {
          productId: productId,
          orgId: organizationId,
        })
      }

      const res = await updateProduct({
        productId,
        dataUpdate: { variants: arr },
      })

      if (res.status === 'successful') {
        onUpdate?.()
      }
    }
    setError(null)
    setEdit(!edit)
  }

  return (
    <div className="mb-5">
      <SubTabHeader
        title="Variants"
        buttonText={edit ? 'Save' : 'Edit'}
        onClick={handleEditChange}
      />
      <SubCard>
        {error && <p className=" text-xs text-red-500 mb-2">{error.message}</p>}
        <Box>
          <Typography color={'primary.main'}>COLOR</Typography>
          {colorVariants.length === 0 && !edit && (
            <Typography ml={2}>No color option</Typography>
          )}
          {(colorVariants.length > 0 || edit) && (
            <VariantsTable
              type="color"
              edit={edit}
              columns={variantsTableColumns}
              rowData={colorVariants}
              onRowAdd={onColorTableRowsChange}
              onRowRemove={onColorTableRowsChange}
            />
          )}

          <Typography color={'primary.main'} mt={2}>
            SIZE
          </Typography>
          {sizeVariants.length === 0 && !edit && (
            <Typography ml={2}>No size option</Typography>
          )}
          {(sizeVariants.length > 0 || edit) && (
            <VariantsTable
              type="size"
              edit={edit}
              columns={variantsTableColumns}
              rowData={sizeVariants}
              onRowAdd={onSizeTableRowsChange}
              onRowRemove={onSizeTableRowsChange}
            />
          )}
        </Box>
      </SubCard>
    </div>
  )
}

export default VariantsDetails
