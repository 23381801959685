import { FC, ChangeEvent, useState, useEffect } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Paper,
  CardContent,
  Typography,
  IconButton,
  Link,
} from '@mui/material'
import { useParams } from 'react-router-dom'

import EditButton from 'pages/dashboard/components/EditButton'
import InputFileUpload from 'pages/dashboard/components/InputFileUpload'
import { getPresignedUrl, uploadFile, deleteFile } from 'services/files'
import { updateExistingCategory } from 'services/products/categories'
import { validateCategoryImage } from 'utils/validation'

interface DetailsSectionProps {
  description: string
  imageUrl: string | null
  setImageUrl: (url: string) => void
  setDescription: (desc: string) => void
}

const fileUrl = process.env.REACT_APP_FILE_URL

const CategoryDetailsSection: FC<DetailsSectionProps> = ({
  description,
  imageUrl,
  setImageUrl,
  setDescription,
}) => {
  const [image, setImage] = useState<string | null>(imageUrl)
  const [error, setError] = useState<{ type: string; message: string } | null>(
    null
  )
  const [uploadProgress, setUploadProgress] = useState<{
    progress: number
    fileName: string
  }>({
    progress: 0,
    fileName: '',
  })
  const [isUploading, setIsUploading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { categoryId } = useParams<{ categoryId: string }>()

  useEffect(() => {
    setImage(imageUrl)
  }, [imageUrl])

  const handleFileChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (!e.target.files) return

    if (!categoryId) {
      setError({
        type: 'image',
        message: 'Category ID is missing. Cannot update category.',
      })
      return
    }

    const file = e.target.files[0]
    const validate = await validateCategoryImage(file)

    if (!validate.valid) {
      return setError({
        type: 'image',
        message: validate.error || 'An error occurred during validation.',
      })
    }

    setIsUploading(true)

    try {
      const signedUrl = await getPresignedUrl(file.name)
      setUploadProgress({ progress: 0, fileName: file.name })

      const result = await uploadFile(file, signedUrl, (progress) => {
        setUploadProgress({ progress, fileName: file.name })
      })

      if (result.status === 'successful') {
        const imageUrl = `${fileUrl}/${file.name}`
        setImage(imageUrl)
        setImageUrl(imageUrl)
        setError(null)

        const updateData = { imageUrl }
        const updateResult = await updateExistingCategory(
          categoryId,
          updateData
        )

        if (updateResult.status !== 'successful') {
          setError({
            type: 'update',
            message: updateResult.message || 'Failed to update category.',
          })
        }
      } else {
        setError({
          type: 'upload',
          message: 'File upload failed.',
        })
      }
    } catch (error) {
      setError({
        type: 'upload',
        message: 'An error occurred while uploading the file.',
      })
    } finally {
      setIsUploading(false)
    }
  }

  const handleDeleteImage = async (): Promise<void> => {
    if (!image) return

    if (!categoryId) {
      setError({
        type: 'image',
        message: 'Category ID is missing. Cannot delete image.',
      })
      return
    }

    const fileName = image.split('/')[3]

    try {
      const result = await deleteFile(fileName)

      if (result.status === 'successful') {
        setImage(null)
        setImageUrl('')

        const updateData = { imageUrl: '' }
        const updateResult = await updateExistingCategory(
          categoryId,
          updateData
        )

        if (updateResult.status !== 'successful') {
          setError({
            type: 'update',
            message:
              updateResult.message ||
              'Failed to update category after image deletion.',
          })
        }
      } else {
        setError({
          type: 'delete',
          message: 'Image deletion failed.',
        })
      }
    } catch (error) {
      setError({
        type: 'delete',
        message: 'An error occurred while deleting the image.',
      })
    }
  }

  const handleEditDescription = async () => {
    setIsEditing(!isEditing)
    if (!categoryId) {
      console.error('Category ID is missing. Cannot update category.')
      return
    }
    const updateData = {
      description: description,
    }

    try {
      const result = await updateExistingCategory(categoryId, updateData)

      if (result.status === 'successful') {
        setDescription(description)
      } else {
        console.error('Failed to update category:', result.message)
      }
    } catch (error) {
      console.error('Error during update:', error)
    }
  }

  return (
    <>
      <Box
        className="mx-5 mb-4"
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography sx={{ fontWeight: '800' }}>Details</Typography>
        <EditButton
          buttonText={isEditing ? 'Save' : 'Edit'}
          onClick={handleEditDescription}
        />
      </Box>
      <Paper
        className="mx-5 mt-4"
        sx={{
          backgroundColor: 'secondary.main',
          boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
        }}
      >
        <CardContent>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  color: 'text.secondary',
                  fontWeight: '600',
                  marginBottom: 1,
                }}
              >
                Description
              </Typography>
              {isEditing ? (
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="w-full border border-gray-300 rounded py-2 px-3 focus:border-black focus:outline-none"
                />
              ) : (
                <Typography variant="body1" color="text.secondary">
                  {description}
                </Typography>
              )}
            </Box>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
                marginTop: 2,
              }}
            >
              Image
            </Typography>
            {image ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Link
                  href={image}
                  underline="hover"
                  target="_blank"
                  rel="noopener"
                >
                  {image.split('/')[3]}
                </Link>
                {isEditing && (
                  <IconButton
                    onClick={handleDeleteImage}
                    aria-label="delete"
                    size="small"
                  >
                    <DeleteIcon
                      fontSize="inherit"
                      className="text-gray-600 hover:text-red-600"
                    />
                  </IconButton>
                )}
              </Box>
            ) : (
              <InputFileUpload
                name="image"
                onChange={handleFileChange}
                buttonText={isUploading ? 'Uploading' : 'Upload File'}
              />
            )}
            {uploadProgress.progress > 0 && uploadProgress.progress < 100 && (
              <Typography sx={{ fontSize: '0.8rem', color: 'text.secondary' }}>
                {uploadProgress.fileName} - {uploadProgress.progress}%
              </Typography>
            )}
            {error && error.type === 'image' && (
              <p className="text-xs text-red-500 block mt-1">{error.message}</p>
            )}
          </Box>
        </CardContent>
      </Paper>
    </>
  )
}

export default CategoryDetailsSection
