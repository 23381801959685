import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Box, InputAdornment, Typography } from '@mui/material'

import { SubCard } from 'components/card'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import Amplitude from 'lib/amplitude'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import { updateProduct } from 'services/products'

interface PricingDetailsProps {
  productId: number
  price: number
  discountedPrice: number
  onUpdate: () => void
}

const PricingDetails: FC<PricingDetailsProps> = ({
  productId,
  price,
  discountedPrice,
  onUpdate,
}) => {
  const [values, setValues] = useState({ price: '', discountedPrice: '' })
  const [error, setError] = useState({ price: '', discountedPrice: '' })
  const [edit, setEdit] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { organizationId } = useAuth()

  useEffect(() => {
    setValues({
      price: price.toString(),
      discountedPrice: discountedPrice.toString(),
    })
  }, [price, discountedPrice])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'price') {
      setValues((prev) => ({ ...prev, price: e.target.value }))
    } else {
      setValues((prev) => ({ ...prev, discountedPrice: e.target.value }))

      if (+e.target.value > +values.price) {
        setError((prev) => ({
          ...prev,
          discountedPrice: '*Should be less than price',
        }))
      } else {
        setError({ price: '', discountedPrice: '' })
      }
    }
  }

  const handleEdit = async (): Promise<void> => {
    if (!edit && organizationId) {
      Amplitude.trackEvent('PRODUCT_PRICING_EDIT_CLICKED', {
        productId: productId,
        orgId: organizationId,
      })
    }
    if (edit) {
      if (values.price === '' || values.discountedPrice === '') {
        setError({
          price: values.price === '' ? '*Price is required.' : '',
          discountedPrice:
            values.discountedPrice === ''
              ? '*DiscountedPrice is required.'
              : '',
        })
        return
      }

      if (+values.price < +values.discountedPrice) return

      if (
        +values.price === price &&
        +values.discountedPrice === discountedPrice
      ) {
        setEdit(false)
        return
      }

      if (organizationId) {
        Amplitude.trackEvent('PRODUCT_PRICING_SAVE_CLICKED', {
          productId: productId,
          orgId: organizationId,
        })
      }

      const res = await updateProduct({
        productId,
        dataUpdate: {
          price: +values.price,
          discountedPrice: +values.discountedPrice,
        },
      })

      if (res.status === 'successful') {
        showSnackbar('Product price updated.', 'success')
        onUpdate()
      }
    }
    setEdit(!edit)
  }

  return (
    <div className="mb-5">
      <SubTabHeader
        title="Pricing"
        buttonText={edit ? 'Save' : 'Edit'}
        onClick={handleEdit}
      />
      <SubCard>
        <Box display={'flex'} columnGap={2} rowGap={1}>
          <Box flex={1}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Price
            </Typography>
            {edit ? (
              <CustomTextField
                name="price"
                type="number"
                value={values.price}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                error={Boolean(error.price)}
                helperText={error.price}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>₹{price}</Typography>
            )}
          </Box>
          <Box flex={1}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Discounted Price
            </Typography>
            {edit ? (
              <CustomTextField
                name="discountedPrice"
                type="number"
                value={values.discountedPrice}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                error={error.discountedPrice !== ''}
                helperText={error.discountedPrice}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                ₹{discountedPrice}
              </Typography>
            )}
          </Box>
        </Box>
      </SubCard>
    </div>
  )
}

export default PricingDetails
