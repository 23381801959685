import { ChangeEvent, FC, useEffect, useState } from 'react'

import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Box, Grid, Typography } from '@mui/material'

import { SubCard } from 'components/card'
import { useAuth } from 'hooks/useAuth'
import Amplitude from 'lib/amplitude'
import CategoryInput from 'pages/dashboard/components/CategoryInput'
import CustomTextField from 'pages/dashboard/components/CustomTextField'
import SubTabHeader from 'pages/dashboard/components/SubTabHeader'
import TextCollapse from 'pages/dashboard/components/TextCollapse'
import { getCategories, updateProduct } from 'services/products'
import { Category } from 'types/product'

interface BasicDetailsProps {
  name?: string
  category?: number[]
  description?: string
  productId: number
  onUpdate?: () => void
}

const BasicDetails: FC<BasicDetailsProps> = ({
  name,
  category,
  description,
  productId,
  onUpdate,
}) => {
  const [values, setValues] = useState({ name, category, description })
  const [edit, setEdit] = useState(false)
  const [error, setError] = useState({ fieldName: '', message: '' })
  const { organizationId } = useAuth()
  const [names, setNames] = useState<string | null>(null)

  useEffect(() => {
    const get = async (orgId: number): Promise<void> => {
      const result = await getCategories(orgId)
      if (result?.status === 'successful' && category && category.length > 0) {
        const data = result.data.filter((el: Category) =>
          category.includes(el.id)
        )
        const categoryNames = data.map((el: Category) => el.name).join(', ')
        setNames(categoryNames)
      } else if (category?.length === 0) {
        setNames(null)
      }
    }
    if (organizationId) get(organizationId)
  }, [organizationId, category])

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'name')
      setValues((prev) => ({ ...prev, name: e.target.value }))
  }

  const handleDescriptionChange = (
    event: unknown,
    editor: ClassicEditor
  ): void => {
    const data = editor.getData()
    setValues((prev) => ({ ...prev, description: data }))
  }

  const handleCategoryChange = (ids: number[]): void => {
    setValues((prev) => ({ ...prev, category: ids }))
  }

  const handleEdit = async (): Promise<void> => {
    if (!edit && organizationId) {
      Amplitude.trackEvent('PRODUCT_DETAILS_EDIT_CLICKED', {
        orgId: organizationId,
        productId: productId,
      })
    }
    if (
      edit &&
      (name != values.name ||
        description != values.description ||
        values.category)
    ) {
      if (values.name?.trim() === '') {
        setError({ fieldName: 'name', message: 'Name is required.' })
        return
      }

      if (organizationId) {
        Amplitude.trackEvent('PRODUCT_DETAILS_SAVE_CLICKED', {
          orgId: organizationId,
          productId: productId,
        })
      }

      const res = await updateProduct({
        productId,
        dataUpdate: {
          name: values.name,
          description: values.description,
          categoryIds: values.category,
        },
      })
      if (res.status === 'successful' && res.data) {
        onUpdate?.()
      }
    }
    setEdit(!edit)
  }

  return (
    <div className="mb-5">
      <SubTabHeader
        title="Product Details"
        buttonText={edit ? 'Save' : 'Edit'}
        onClick={handleEdit}
      />
      <SubCard>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item md={6}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Name
            </Typography>
            {edit ? (
              <CustomTextField
                id="name"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={error.fieldName === 'name'}
                helperText={error.fieldName === 'name' && error.message}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>{name}</Typography>
            )}
          </Grid>
          <Grid item md={6}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Category
            </Typography>
            {edit ? (
              <CategoryInput
                onChange={handleCategoryChange}
                defaultValues={values.category}
              />
            ) : (
              <Typography sx={{ fontWeight: '700' }}>
                {names ?? 'N/A'}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: 'text.secondary',
                fontWeight: '600',
              }}
            >
              Description
            </Typography>
            {edit ? (
              <Box className=" flex">
                <Box className="flex-1 w-14">
                  <CKEditor
                    editor={ClassicEditor}
                    data={values.description}
                    onChange={handleDescriptionChange}
                  />
                </Box>
              </Box>
            ) : (
              <TextCollapse text={description ?? 'N/A'} />
            )}
          </Grid>
        </Grid>
      </SubCard>
    </div>
  )
}

export default BasicDetails
