import { useState } from 'react'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button, IconButton } from '@mui/material'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'hooks/useSnackbar'
import TabTitle from 'pages/dashboard/components/TabTitle'
import { createNewCategory } from 'services/products/categories'

import CategoryDetails from '../components/CategoryDetails'
import { validationSchema } from '../schema/CreateCategorySchema'

const AddCategoryPage = () => {
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()
  const [imageUrl, setImageUrl] = useState<string>('')
  const { organizationId } = useAuth()

  const handleBackClick = () => {
    navigate(-1)
  }

  const initialValues = {
    name: '',
    description: '',
  }

  const submitHandler = async (values: typeof initialValues) => {
    try {
      if (!organizationId) {
        return
      }
      const createData = {
        name: values.name,
        description: values.description,
        imageUrl: imageUrl,
        organizationId: organizationId,
      }

      const response = await createNewCategory(createData)

      if (response.status === 'successful') {
        showSnackbar('Category created successfully', 'success')
        navigate('/dashboard/products/categories')
      } else {
        showSnackbar('Failed to create category', 'error')
      }
    } catch (error) {
      showSnackbar('Something went wrong', 'error')
    }
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
        className="mx-4"
      >
        <IconButton sx={{ color: 'black' }} onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <TabTitle title="New Category" />
      </Box>
      <Box className="mb-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ values, errors, touched, handleChange }) => (
            <Form>
              <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
                <Box flex={2}>
                  <CategoryDetails
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    setImageUrl={setImageUrl}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt={2}
              >
                <Button type="submit" variant="contained" color="primary">
                  Add Category
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  )
}

export default AddCategoryPage
